// TODO:
// 1. Add jsdoc comments
// 2. Move useColumnCount.ts and useChunkSize.ts to this file
// 3. Refactor the logic of useColumnCount.ts and useChunkSize.ts according to gap implementation
// 4. Use composable everywhere in code, refactor existing places to use useContentItemsGridConfig.ts
// 5. Create type for config object
export const useContentItemsGridConfig = (config: { [key: string]: { [key: string]: number } }) => {
  const isXsDisplay = inject<Ref<boolean>>('isXsDisplay');
  const isMobileMode = inject<Ref<boolean>>('isMobileMode');
  const gap = computed(() => {
    const gapFallback = 8;

    if (isXsDisplay?.value && config.xs) {
      return config.xs?.gap ?? gapFallback;
    }
    if (isMobileMode?.value && config.mobile) {
      return config.mobile?.gap ?? gapFallback;
    }

    return config.desktop?.gap ?? gapFallback;
  });

  return { gap };
};
